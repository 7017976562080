import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { LockClosedIcon, UserIcon, BuildingOfficeIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import axios from '../axios';
import { useTranslation } from 'react-i18next';

const areas = [
  { id: 'cybersecurity', name: 'Cybersecurity', icon: LockClosedIcon },
  { id: 'data_privacy', name: 'Data Privacy', icon: UserIcon },
  { id: 'organization', name: 'Organization', icon: BuildingOfficeIcon },
  { id: 'other', name: 'Other', icon: EllipsisHorizontalIcon },
];

const priorities = [
  { id: 'normal', name: 'Normal', color: 'text-black' },
  { id: 'high', name: 'High', color: 'text-orange-300' },
  { id: 'very_high', name: 'Very High', color: 'text-red-500' },
];

const fixAvatarUrl = (url) => {
  if (!url) return url;

  const malformedHost = 'dev.cenedril.net,%20dev.cenedril.net';
  const correctHost = 'dev.cenedril.net';

  if (url.includes(malformedHost)) {
    return url.replace(malformedHost, correctHost);
  }

  return url;
};

const UserInitials = ({ firstName, lastName }) => {
  const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
  return (
    <div className="h-6 w-6 rounded-full bg-secondary-color flex items-center justify-center">
      <span className="text-white text-xs font-bold">{initials}</span>
    </div>
  );
};

const TaskCard = ({ task, users, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(task.name);

  const handleUpdate = async (field, value) => {
    try {
      const updateData = { [field]: value };
      
      // If updating assigned_to or approved_by, also update assigned_by
      if (field === 'assigned_to_id' || field === 'approved_by_id') {
        updateData.assigned_by_id = currentUser.id;
      }

      await axios.put(`/api/v1/tasks/${task.id}`, updateData, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      onUpdate();
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleNameUpdate = async () => {
    await handleUpdate('name', editedName);
    setIsEditing(false);
  };

  const currentUser = JSON.parse(sessionStorage.getItem('user'));

  const UserMenuItem = ({ user, onClick }) => {
    const [imageError, setImageError] = useState(false);

    return (
      <button
        className="group flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        onClick={onClick}
      >
        {user.avatar_url && !imageError ? (
          <img 
            src={fixAvatarUrl(user.avatar_url)} 
            alt={`${user.first_name} ${user.last_name}`} 
            className="w-6 h-6 rounded-full mr-0"
            onError={() => setImageError(true)}
          />
        ) : (
          <UserInitials 
            firstName={user.first_name} 
            lastName={user.last_name} 
          />
        )}
        <span className="ml-2">{user.first_name} {user.last_name}</span>
      </button>
    );
  };

  const UserDisplay = ({ userId, userName }) => {
    const user = users.find(u => u.id === userId);
    const [imageError, setImageError] = useState(false);

    return (
      <div className="flex items-center">
        {user?.avatar_url && !imageError ? (
          <img 
            src={fixAvatarUrl(user.avatar_url)} 
            alt={userName} 
            className="w-6 h-6 rounded-full mr-0"
            onError={() => setImageError(true)}
          />
        ) : (
          <UserInitials 
            firstName={user?.first_name} 
            lastName={user?.last_name} 
          />
        )}
        <span className="ml-2">{userName}</span>
      </div>
    );
  };

  return (
    <div className="bg-white p-4 mb-4 rounded shadow">
      <div className="flex items-center mb-2">
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="text-sm text-gray-500 flex items-center">
            {React.createElement(areas.find(a => a.id === task.area)?.icon, { className: 'w-4 h-4 mr-1' })}
            {areas.find(a => a.id === task.area)?.name}
          </Menu.Button>
          <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {areas.map((area) => (
                <Menu.Item key={area.id}>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } group flex w-full items-center px-4 py-2 text-sm`}
                      onClick={() => handleUpdate('area', area.id)}
                    >
                      {React.createElement(area.icon, { className: 'w-5 h-5 mr-3' })}
                      {area.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
      </div>
      {isEditing ? (
        <input
          type="text"
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
          onBlur={handleNameUpdate}
          onKeyPress={(e) => e.key === 'Enter' && handleNameUpdate()}
          className="font-bold mb-2 w-full"
          autoFocus
        />
      ) : (
        <h3 className="font-bold mb-2 cursor-pointer" onClick={() => setIsEditing(true)}>
          {task.name}
        </h3>
      )}
      <div className="mb-2">
        <label className="text-xs text-gray-500">Assigned to</label>
        <Menu as="div" className="relative inline-block text-left w-full">
          <Menu.Button className="flex items-center w-full mt-1">
            <UserDisplay userId={task.assigned_to_id} userName={task.assigned_to_name} />
          </Menu.Button>
          <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {users.map((user) => (
                <Menu.Item key={user.id}>
                  {({ active }) => (
                    <UserMenuItem 
                      user={user} 
                      onClick={() => handleUpdate('assigned_to_id', user.id)}
                    />
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
      </div>
      <div className="mb-2">
        <label className="text-xs text-gray-500">Approval by</label>
        <Menu as="div" className="relative inline-block text-left w-full">
          <Menu.Button className="flex items-center w-full mt-1">
            <UserDisplay userId={task.approved_by_id} userName={task.approved_by_name} />
          </Menu.Button>
          <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {users.map((user) => (
                <Menu.Item key={user.id}>
                  {({ active }) => (
                    <UserMenuItem 
                      user={user} 
                      onClick={() => handleUpdate('approved_by_id', user.id)}
                    />
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
      </div>
      <div>
        <label className="text-xs text-gray-500">Priority</label>
        <Menu as="div" className="relative inline-block text-left w-full">
          <Menu.Button className={`${priorities.find(p => p.id === task.priority)?.color} w-full text-left mt-1`}>
            {priorities.find(p => p.id === task.priority)?.name}
          </Menu.Button>
          <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {priorities.map((priority) => (
                <Menu.Item key={priority.id}>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } group flex w-full items-center px-4 py-2 text-sm ${priority.color}`}
                      onClick={() => handleUpdate('priority', priority.id)}
                    >
                      {priority.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
      </div>
      <button 
        className="mt-4 text-sm text-blue-600 hover:text-blue-800"
        onClick={() => {/* Implement task detail view */}}
      >
        See detail
      </button>
    </div>
  );
};

export default TaskCard;