import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const ismsStructure = [
  {
    id: "introduction",
    name: "Introduction",
    pages: [
      { id: "welcome", name: "Welcome" },
      { id: "what-do-i-need", name: "What do I need?" },
      { id: "references-and-materials", name: "References and additional materials" },
      { id: "core-aspects", name: "Core aspects of information security" },
    ]
  },
  {
    id: "organizational-context-and-scope",
    name: "Organizational Context and Scope",
    pages: [
      { id: "organization-security-perspective", name: "Organization from a security perspective" },
      { id: "understanding-organization-context", name: "Understanding the organization and its context" },
      { id: "external-context", name: "External context of the organization" },
      { id: "internal-context", name: "Internal context of the organization" },
      { id: "stakeholder-needs", name: "Understanding the needs and expectations of interested parties" },
      { id: "stakeholder-requirements", name: "What are your stakeholder requirements regarding information security?" },
      { id: "prioritize-requirements", name: "How do you prioritize your stakeholders requirements regarding information security?" },
      { id: "getting-ready", name: "Getting ready for the Information Security Management System" },
    ]
  },
  {
    id: "leadership-commitment",
    name: "Leadership Commitment",
    pages: [
      { id: "leadership", name: "Leadership" },
      { id: "top-management-responsibilities", name: "Top-Management and team responsibilities" },
      { id: "commitment-charta", name: "Signing Charta for commitment and taking over responsibility" },
      { id: "security-policy", name: "Information Security Policy" },
      { id: "next-steps", name: "Next Steps: communication and review" },
    ]
  },
  {
    id: "risk-policy",
    name: "Risk Policy",
    pages: [
      { id: "risks", name: "Risks" },
      { id: "risk-actions", name: "Actions to address risks and opportunities" },
      { id: "risk-policy", name: "Information security risk policy" },
      { id: "risk-evaluation-criteria", name: "Risk evaluation criteria scoring system" },
      { id: "risk-impact-criteria", name: "Risk impact criteria" },
      { id: "protection-classification", name: "Protection Classification / Valuation criteria" },
      { id: "risk-acceptance", name: "Risk acceptance" },
      { id: "risk-policy-review", name: "Risk policy review" },
    ]
  },
  {
    id: "risk-assessment",
    name: "Risk Assessment",
    pages: [
      { id: "risk-assessment", name: "Information security risk assessment" },
      { id: "dream-team", name: "Assemble a dream team" },
    ]
  },
  {
    id: "risk-identification",
    name: "Risk Identification",
    pages: [
      { id: "protection-targets", name: "What are we protecting against?" },
      { id: "primary-assets-processes", name: "What are we protecting? Identification of primary assets: processes" },
      { id: "risk-events", name: "Identification of risk events" },
      { id: "primary-assets-subprocesses", name: "Identification of primary assets: subprocesses" },
      { id: "primary-assets-information", name: "Identification of primary assets: information" },
      { id: "supporting-assets", name: "Identification of supporting assets" },
      { id: "asset-list", name: "List of all primary and supporting assets" },
      { id: "threats", name: "Threats to your assets" },
      { id: "vulnerabilities", name: "Vulnerabilities to your assets" },
      { id: "risk-scenarios", name: "Risk scenarios" },
    ]
  },
  {
    id: "risk-analysis",
    name: "Risk Analysis",
    pages: [
      { id: "analyzing-risks", name: "Analyzing the risks" },
      { id: "impact-assessment", name: "Assessment of impact of consequences" },
      { id: "incident-likelihood", name: "Assessment of incident likelihood" },
      { id: "risk-level-determination", name: "Risk level determination" },
    ]
  },
  {
    id: "risk-evaluation",
    name: "Risk Evaluation",
    pages: [
      { id: "risk-evaluation", name: "Risk evaluation" },
      { id: "risk-prioritization", name: "Risk prioritization and risk owners" },
      { id: "prioritized-risk-list", name: "Prioritized list of risks" },
    ]
  },
  {
    id: "risk-treatment",
    name: "Risk Treatment",
    pages: [
      { id: "risk-treatment", name: "Risk treatment" },
      { id: "risk-avoidance", name: "Risk avoidance" },
      { id: "risk-retention", name: "Full risk retention" },
      { id: "risk-controls", name: "Risk controls for risk modification" },
      { id: "risk-sharing", name: "Risk sharing" },
      { id: "treatment-effects", name: "The assumed effects of your risk treatment" },
      { id: "statement-of-applicability", name: "Statement of Applicability" },
    ]
  },
  {
    id: "security-objectives",
    name: "Information Security Objectives",
    pages: [
      { id: "security-objectives", name: "Information Security Objectives" },
    ]
  },
  {
    id: "implementation",
    name: "Implementation",
    pages: [
      { id: "implement-controls", name: "Implement risk control measures" },
    ]
  },
  {
    id: "operation",
    name: "Operation",
    pages: [
      { id: "operative-documentation", name: "Plan operative documentation measures" },
    ]
  },
  {
    id: "performance-evaluation",
    name: "Performance Evaluation",
    pages: [
      { id: "performance-evaluation", name: "Plan performance evaluation dates" },
    ]
  },
];

const ISMSProgress = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // Extract the current chapter and page from the URL
  const [, , chapterId, pageId] = location.pathname.split('/');

  // Find the current chapter
  const currentChapter = ismsStructure.find(chapter => chapter.id === chapterId);

  if (!currentChapter) return null; // If no matching chapter, don't render anything

  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {currentChapter.pages.map((page, index) => (
          <li key={page.id} className="md:flex-1">
            {pageId === page.id ? (
              <a
                href={`/isms/${chapterId}/${page.id}`}
                className="flex flex-col border-l-4 border-primary-color py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-10 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-xs font-medium text-primary-color">{t(`ISMS.${chapterId}.section${index + 1}`)}</span>
                <span className="text-xs font-bold text-black">{t(`ISMS.${chapterId}.${page.id}`)}</span>
              </a>
            ) : (
              <a
                href={`/isms/${chapterId}/${page.id}`}
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-6 md:pl-0 md:pt-4"
              >
                <span className="text-xs font-medium text-gray-500 group-hover:text-primary-color">{t(`ISMS.${chapterId}.section${index + 1}`)}</span>
                <span className="text-xs font-bold text-gray-500 group-hover:text-gray-700">{t(`ISMS.${chapterId}.${page.id}`)}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default ISMSProgress;