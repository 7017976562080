import React from 'react';
import { useTranslation } from 'react-i18next';
import ISMSProgress from '../../../../components/wizard_components/ISMSProgress';

const ISMSWhatDoINeed = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 py-8">
      
      <ISMSProgress />
      
      <div className="mt-8">
      <h1 className="text-3xl font-bold mb-6">{t('ISMS.introduction.welcome.title')}</h1>


      </div>
    </div>
  );
};

export default ISMSWhatDoINeed;