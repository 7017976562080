import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Lottie from 'lottie-react';
import ISMSProgress from '../../../../components/wizard_components/ISMSProgress';
import animationData from '../../../../components/assets/welcome-animation.json'

const ISMSWelcome = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 py-8">
      <ISMSProgress />
      
      <div className="mt-8 text-center">
        <h1 className="text-3xl font-bold mb-6">{t('ISMS.introduction.welcome.title')}</h1>
        
        <div className="w-64 h-64 mx-auto mb-8">
          <Lottie animationData={animationData} loop={true} />
        </div>

        <div className="text-left max-w-3xl mx-auto">
          <p className="mb-4">{t('ISMS.introduction.welcome.description')}</p>

          <ol className="list-decimal pl-6 mb-4">
            <li className="mb-2">
              <Trans i18nKey="ISMS.introduction.welcome.option1">
                You already have an Information Security Management System and want to check its maturity and/or show off to your stakeholders how good of a job you are doing. This will maybe take <strong>between a few days or a couple of weeks</strong> <strong>of working hours</strong> to achieve (based on the availability and actuality of your documentation.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="ISMS.introduction.welcome.option2">
                You want to implement an Information Security Management System and start your journey here. If you do - great job! We believe you chose the right time (now) and place (here). Please be aware that <strong>creating and implementing an "ISMS" can take everything between a handful of weeks (small, low complexity organization, high commitment) to a year (large, complex organization, low commitment)</strong>. As you can gather from the text in brackets, a lot of this depends on the nature of your organization and more than everything else, how much time, energy and other necessary resources your organization is committed to give to this endeavour.
              </Trans>
            </li>
          </ol>

          <p>{t('ISMS.introduction.welcome.conclusion')}</p>
        </div>
      </div>
    </div>
  );
};

export default ISMSWelcome;