import React, { useState, useEffect, useRef } from 'react';
import axios from '../axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { countryData } from '../constants/countryData';
import Notification from '../components/Notification';

const sortedCountryData = [...countryData].sort((a, b) => a.name.localeCompare(b.name));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Settings() {
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [yearFounded, setYearFounded] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState({ id: 'en', name: 'English' });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [notificationAlert, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLastUser, setIsLastUser] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [country, setCountry] = useState(sortedCountryData[0]);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    // Fetch user data when component mounts
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      const userData = response.data;

      setCompanyName(userData.entity.name);
      setWebsite(userData.entity.website.replace(/^https?:\/\//, ''));
      setYearFounded(userData.entity.year_founded.toString());
      setFirstName(userData.first_name);
      setLastName(userData.last_name);
      setEmail(userData.email);
      setCountry(sortedCountryData.find(c => c.code === userData.entity.country) || sortedCountryData[0]);
      setLanguage({ id: userData.language, name: userData.language === 'en' ? 'English' : 'Deutsch' });
      setIsLastUser(userData.is_last_user);

      // Update the avatar URL from the response
      setAvatarUrl(userData.avatar_url);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('error_fetching_user_data'),
        errors: [error.message]
      });
    }
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatarPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    const userPayload = {
      entity: {
        name: companyName,
        website: website.startsWith('http') ? website : `https://${website}`,
        year_founded: parseInt(yearFounded, 10),
        country: country.code,
      },
      first_name: firstName,
      last_name: lastName,
      email,
      language: language.id,
    };

    if (newPassword) {
      userPayload.current_password = currentPassword;
      userPayload.password = newPassword;
      userPayload.password_confirmation = confirmNewPassword;
    }

    // Append the JSON payload
    formData.append('user_data', JSON.stringify(userPayload));

    // Append the file if it exists
    if (fileInputRef.current.files[0]) {
      formData.append('avatar', fileInputRef.current.files[0]);
    }

    try {
      const response = await axios.put('/api/v1/live_data/user_data', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });

      // Handle successful update
      setNotification({
        show: true,
        type: 'success',
        message: t('settings_updated_successfully')
      });
      i18n.changeLanguage(language.id);

      // Update the language in sessionStorage
      const userData = JSON.parse(sessionStorage.getItem('user'));
      userData.language = language.id;
      sessionStorage.setItem('user', JSON.stringify(userData));

    } catch (error) {
      console.error('Error updating settings:', error.response?.data || error.message);
      setNotification({
        show: true,
        type: 'error',
        message: t('error_updating_settings'),
        errors: [error.message]
      });
    }
  };

  const confirmDeleteAccount = async () => {
    try {
      await axios.delete('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        },
        data: { confirm_entity_deletion: isLastUser }
      });
      sessionStorage.removeItem('authToken');
      navigate('/deletion-success');
    } catch (error) {
      console.error('Error deleting account:', error.response?.data || error.message);
      setNotification({
        show: true,
        type: 'error',
        message: t('error_deleting_account'),
        errors: [error.message]
      });
    } finally {
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 pb-20">
      <Notification
        show={notificationAlert.show}
        setShow={(show) => setNotification({ ...notificationAlert, show })}
        type={notificationAlert.type}
        message={notificationAlert.message}
        errors={notificationAlert.errors}
      />
      <div className="max-w-3xl mx-auto">
        <h2 className="text-2xl font-bold mb-6 text-left text-primary-color">{t('settings')}</h2>
        <form onSubmit={handleSubmit}>
          {/* Company Profile Section */}
          <div className="space-y-12">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-primary-color">{t('company_profile')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {t('company_profile_info')}
                </p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-6">
                  <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('company_name')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="company-name"
                      id="company-name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('website')}
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-color w-full">
                      <span className="flex select-none bg-white items-center pl-3 text-gray-500 sm:text-sm">https://</span>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        className="block flex-1 border-0 bg-white py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="www.example.com"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="year-founded" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('year_founded')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="year-founded"
                      id="year-founded"
                      value={yearFounded}
                      onChange={(e) => setYearFounded(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                      min="1000"
                      max={new Date().getFullYear() + 2}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900">{t('country')}</label>
                  <div className="relative mt-2">
                    <Listbox value={country} onChange={setCountry}>
                      {({ open }) => (
                        <>
                          <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-color sm:text-sm sm:leading-6">
                            <span className="block truncate">{t(`Countries.${country.name}`)}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <ListboxOptions
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            {sortedCountryData.map((countryOption) => (
                              <ListboxOption
                                key={countryOption.code}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-primary-color text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )
                                }
                                value={countryOption}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {t(`Countries.${countryOption.name}`)}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-primary-color',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>

              </div>
            </div>

            {/* Personal Information Section */}

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-primary-color">{t('personal_information')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">{t('update_personal_info')}</p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('first_name')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('last_name')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('email_address')}
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900">{t('language')}</label>
                  <div className="relative mt-2">
                    <Listbox value={language} onChange={setLanguage}>
                      {({ open }) => (
                        <>
                          <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-color sm:text-sm sm:leading-6">
                            <span className="block truncate">{language.name}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <ListboxOptions
                            transition="true"
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                          >
                            {[{ id: 'en', name: 'English' }, { id: 'de', name: 'Deutsch' }].map((lang) => (
                              <ListboxOption
                                key={lang.id}
                                className={({ focus }) =>
                                  classNames(
                                    focus ? 'bg-primary-color text-white' : '',
                                    !focus ? 'text-gray-900' : '',
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                  )
                                }
                                value={lang}
                              >
                                {({ selected, focus }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {lang.name}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          focus ? 'text-white' : 'text-primary-color',
                                          'absolute inset-y-0 right-0 flex items-center pr-4',
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="avatar" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('profile_picture')}
                  </label>
                  <div className="mt-2 flex items-center space-x-4">
                    {avatarPreview ? (
                      <img
                        src={avatarPreview}
                        alt="Avatar preview"
                        className="h-12 w-12 rounded-full object-cover border border-gray-300"
                      />
                    ) : avatarUrl ? (
                      <img
                        src={avatarUrl}
                        alt={`${firstName} ${lastName}`}
                        className="h-12 w-12 rounded-full object-cover border border-gray-300"
                      />
                    ) : (
                      <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100 flex items-center justify-center border border-gray-300">
                        <svg className="h-6 w-6 text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    )}
                    <input
                      type="file"
                      id="avatar"
                      name="avatar"
                      accept="image/*"
                      className="hidden"
                      onChange={handleAvatarChange}
                      ref={fileInputRef}
                    />
                    <button
                      type="button"
                      onClick={() => fileInputRef.current.click()}
                      className="flex-1 py-1.5 px-4 text-sm font-semibold shadow-sm bg-primary-color text-white hover:bg-secondary-color rounded-md flex items-center justify-center"
                    >
                      {t('upload')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Password Change Section */}
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-primary-color">{t('change_password')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">{t('leave_blank_if_no_change')}</p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-6">
                  <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('current_password')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      name="current-password"
                      id="current-password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('new_password')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      name="new-password"
                      id="new-password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="confirm-new-password" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('confirm_new_password')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      name="confirm-new-password"
                      id="confirm-new-password"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-between gap-x-6">
            <button
              type="button"
              className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
              onClick={() => setShowDeleteModal(true)}
            >
              {t('delete_account')}
            </button>
            <div>
              <button
                type="button"
                className="text-sm leading-6 text-gray-900"
                onClick={() => navigate('/dashboard')}
              >
                {t('cancel')}
              </button>
              <button
                type="submit"
                className="ml-3 rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
              >
                {t('save_changes')}
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Delete Account Modal */}
      <Transition show={showDeleteModal} as={React.Fragment}>
        <Dialog onClose={() => setShowDeleteModal(false)} className="relative z-10">
          <TransitionChild
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  {/* Modal content */}
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t('delete_account')}
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t('delete_account_confirmation')}
                        </p>
                        {isLastUser && (
                          <p className="text-sm text-red-500 mt-2">
                            {t('last_user_delete_confirmation')}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={confirmDeleteAccount}
                    >
                      {t('confirm_delete')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}