import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';

const StartupWizardNotary = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto p-4">

<StartupWizardProgress />

      <h1 className="text-2xl font-bold mb-4">{t('StartupWizard.notary')}</h1>
      <p className="mb-4">{t('StartupWizard.notaryDescription')}</p>
      <Link to="/startup-wizard" className="bg-primary-color text-white px-4 py-2 rounded">
        {t('StartupWizard.finish')}
      </Link>
    </div>
  );
};

export default StartupWizardNotary;