import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';

const questions = [
  {
    id: 'capital',
    questionKey: 'legalForm.questions.capital.question',
    options: [
      { labelKey: 'legalForm.questions.capital.options.little', value: 'little', score: { UG: 1 } },
      { labelKey: 'legalForm.questions.capital.options.medium', value: 'medium', score: { GmbH: 1 } },
      { labelKey: 'legalForm.questions.capital.options.lot', value: 'lot', score: { AG: 1 } },
    ],
  },
  {
    id: 'profits',
    questionKey: 'legalForm.questions.profits.question',
    options: [
      { labelKey: 'legalForm.questions.profits.options.yes', value: 'yes', score: { GmbH: 1, AG: 1 } },
      { labelKey: 'legalForm.questions.profits.options.no', value: 'no', score: { UG: 1 } },
    ],
  },
  {
    id: 'scope',
    questionKey: 'legalForm.questions.scope.question',
    options: [
      { labelKey: 'legalForm.questions.scope.options.small', value: 'small', score: { UG: 1 } },
      { labelKey: 'legalForm.questions.scope.options.medium', value: 'medium', score: { GmbH: 2 } },
      { labelKey: 'legalForm.questions.scope.options.large', value: 'large', score: { AG: 1 } },
    ],
  },
];

const legalForms = [
  {
    name: 'UG',
    fullNameKey: 'legalForm.types.UG.fullName',
    minCapitalKey: 'legalForm.types.UG.minCapital',
    prosKeys: ['legalForm.types.UG.pros.1', 'legalForm.types.UG.pros.2', 'legalForm.types.UG.pros.3', 'legalForm.types.UG.pros.4'],
    consKeys: ['legalForm.types.UG.cons.1', 'legalForm.types.UG.cons.2', 'legalForm.types.UG.cons.3', 'legalForm.types.UG.cons.4'],
  },
  {
    name: 'GmbH',
    fullNameKey: 'legalForm.types.GmbH.fullName',
    minCapitalKey: 'legalForm.types.GmbH.minCapital',
    prosKeys: ['legalForm.types.GmbH.pros.1', 'legalForm.types.GmbH.pros.2', 'legalForm.types.GmbH.pros.3', 'legalForm.types.GmbH.pros.4'],
    consKeys: ['legalForm.types.GmbH.cons.1', 'legalForm.types.GmbH.cons.2', 'legalForm.types.GmbH.cons.3', 'legalForm.types.GmbH.cons.4'],
  },
  {
    name: 'AG',
    fullNameKey: 'legalForm.types.AG.fullName',
    minCapitalKey: 'legalForm.types.AG.minCapital',
    prosKeys: ['legalForm.types.AG.pros.1', 'legalForm.types.AG.pros.2', 'legalForm.types.AG.pros.3', 'legalForm.types.AG.pros.4'],
    consKeys: ['legalForm.types.AG.cons.1', 'legalForm.types.AG.cons.2', 'legalForm.types.AG.cons.3', 'legalForm.types.AG.cons.4'],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function LegalFormWizard() {
  const { wizardName, updateWizardData, liveMode, toggleLiveMode } = useWizard();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [answers, setAnswers] = useState({});
  const [selectedLegalForm, setSelectedLegalForm] = useState(null);
  const [scores, setScores] = useState({ UG: 0, GmbH: 0, AG: 0 });
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });

  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'legalForm');

  useEffect(() => {
    if (data) {
      setAnswers(data.answers || {});
      setSelectedLegalForm(data.selectedLegalForm);
    }
  }, [data]);

  useEffect(() => {
    calculateScores();
  }, [answers]);

  const calculateScores = () => {
    const newScores = { UG: 0, GmbH: 0, AG: 0 };
    Object.entries(answers).forEach(([questionId, answer]) => {
      const question = questions.find(q => q.id === questionId);
      const option = question.options.find(o => o.value === answer);
      if (option && option.score) {
        Object.entries(option.score).forEach(([form, score]) => {
          newScores[form] += score;
        });
      }
    });
    setScores(newScores);
  };

  const getRecommendedLegalForm = () => {
    return Object.entries(scores).reduce((a, b) => a[1] > b[1] ? a : b)[0];
  };

  const handleSave = async () => {
    const data = {
      answers,
      selectedLegalForm,
    };

    const result = await saveData(wizardName, 'legalForm', data);
    if (result.success) {
      setNotification({ show: true, type: 'success', message: t('legalForm.notifications.dataSaved') });
      updateWizardData('legalForm', data, false);
      updateWizardData('legalForm', data, true);
      setTimeout(() => navigate('/startup-wizard/company'), 1000);
    } else {
      setNotification({ show: true, type: 'error', message: t('legalForm.notifications.errorSaving') });
    }
  };

  const autoSaveDraft = useCallback(async () => {
    if (liveMode) {
      const data = {
        answers,
        selectedLegalForm,
      };
      const result = await saveAsDraft(wizardName, 'legalForm', data);
      if (result.success) {
        updateWizardData('legalForm', data, true);
      }
    }
  }, [liveMode, answers, selectedLegalForm, wizardName, updateWizardData]);

  useEffect(() => {
    if (liveMode) {
      const debouncedAutoSave = debounce(autoSaveDraft, 1000);
      debouncedAutoSave();
      return () => debouncedAutoSave.cancel();
    }
  }, [liveMode, answers, selectedLegalForm, autoSaveDraft]);

  const handleSaveAsDraft = async () => {
    const data = {
      answers,
      selectedLegalForm,
    };

    const result = await saveAsDraft(wizardName, 'legalForm', data);
    if (result.success) {
      setNotification({ show: true, type: 'success', message: t('legalForm.notifications.savedAsDraft') });
      updateWizardData('legalForm', data, true);
    } else {
      setNotification({ show: true, type: 'error', message: t('legalForm.notifications.errorSavingDraft') });
    }
  };

  if (loading) return <div>{t('common.loading')}</div>;
  if (error) return <div>{t('common.error', { error: error.message })}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <DataStatusBanner status={dataStatus} />

      <div className="text-left mt-8">
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
          {t('legalForm.title')}
        </p>
        <p className="mt-4 text-lg text-gray-600">
          {t('legalForm.description')}
        </p>
      </div>

      <div className="mt-12 space-y-8">
        {questions.map((question) => (
          <div key={question.id}>
            <h3 className="text-lg font-medium leading-6 text-gray-900">{t(question.questionKey)}</h3>
            <RadioGroup value={answers[question.id]} onChange={(value) => setAnswers({ ...answers, [question.id]: value })} className="mt-4">
              <div className="space-y-4">
                {question.options.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option.value}
                    className={({ checked }) =>
                      classNames(
                        'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                        checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                      )
                    }
                  >
                    {({ checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <div className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(checked ? 'text-[#2A9D8F]' : 'text-gray-900', 'block text-sm font-medium')}
                          >
                            {t(option.labelKey)}
                          </RadioGroup.Label>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        ))}
      </div>

      <div className="mt-16 grid gap-8 lg:grid-cols-3">
        {legalForms.map((form) => {
          const isSelected = selectedLegalForm === form.name;
          const isRecommended = form.name === getRecommendedLegalForm();
          return (
            <button
              key={form.name}
              onClick={() => setSelectedLegalForm(form.name)}
              className={classNames(
                'rounded-lg p-8 text-left',
                isSelected ? 'bg-[#2A9D8F]' : 'bg-white',
                isRecommended ? 'ring-2 ring-[#2A9D8F]' : 'ring-1 ring-gray-200',
                'transition-all duration-200 ease-in-out hover:shadow-lg'
              )}
            >
              <div className="flex items-center justify-between">
                <h3 className={classNames(
                  'text-2xl font-semibold',
                  isSelected ? 'text-white' : 'text-[#2A9D8F]'
                )}>
                  {form.name}
                </h3>
                {isRecommended && (
                  <p className={classNames(
                    "rounded-full px-2.5 py-1 text-xs font-semibold",
                    isSelected ? "bg-white text-[#2A9D8F]" : "bg-[#2A9D8F]/10 text-[#2A9D8F]"
                  )}>
                    {t('legalForm.recommended')}
                  </p>
                )}
              </div>
              <p className={classNames(
                "mt-4 text-sm",
                isSelected ? 'text-white' : 'text-gray-600'
              )}>
                {t(form.fullNameKey)}
              </p>
              <p className={classNames(
                "mt-4 text-xl font-semibold",
                isSelected ? 'text-white' : 'text-gray-900'
              )}>
                {t(form.minCapitalKey)}
              </p>
              <p className={classNames(
                "text-sm",
                isSelected ? 'text-white' : 'text-gray-600'
              )}>
                {t('legalForm.minimumCapital')}
              </p>
              <div
                className={classNames(
                  'mt-6 w-full rounded-md px-3 py-2 text-sm font-semibold text-center',
                  isSelected
                    ? 'bg-white text-[#2A9D8F] ring-2 ring-white'
                    : 'bg-white text-[#2A9D8F] ring-1 ring-inset ring-[#2A9D8F]'
                )}
              >
                {isSelected ? t('legalForm.selected') : t('legalForm.select')}
              </div>
              <ul className="mt-8 space-y-3 text-sm">
                {form.prosKeys.map((proKey) => (
                  <li key={proKey} className="flex gap-x-3">
                    <HandThumbUpIcon className={classNames(
                      "h-5 w-5 flex-shrink-0",
                      isSelected ? "text-white" : "text-green-500"
                    )} aria-hidden="true" />
                    <span className={isSelected ? 'text-white' : 'text-black'}>{t(proKey)}</span>
                  </li>
                ))}
                {form.consKeys.map((conKey) => (
                  <li key={conKey} className="flex gap-x-3">
                    <HandThumbDownIcon className={classNames(
                      "h-5 w-5 flex-shrink-0",
                      isSelected ? "text-white" : "text-red-500"
                    )} aria-hidden="true" />
                    <span className={isSelected ? 'text-white' : 'text-black'}>{t(conKey)}</span>
                  </li>
                ))}
              </ul>
            </button>
          );
        })}
      </div>

      <ActionButtons
        onCancel={() => navigate('/startup-wizard')}
        onDraft={handleSaveAsDraft}
        onSubmit={handleSave}
        isSubmitting={false}
        currentPage="legalForm"
      />

      <Notification
        show={notification.show}
        setShow={(show) => setNotification(prev => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />
    </div>
  );
}

// Updated debounce function with cancel functionality
function debounce(func, wait) {
  let timeout;

  function debouncedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  // Add a cancel method to the debounced function
  debouncedFunction.cancel = () => {
    clearTimeout(timeout);
  };

  return debouncedFunction;
}