// src/pages/Tasks.js
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import KanbanBoard from '../components/KanbanBoard';

export default function Tasks() {
  const { user } = useContext(AuthContext);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Tasks</h1>
    <KanbanBoard />
    </div>
  );
}