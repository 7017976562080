// src/components/NewTaskCard.js
import React, { useState } from 'react';
import axios from '../axios';

const NewTaskCard = ({ onAdd }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [newTaskName, setNewTaskName] = useState('');

  const handleAddTask = async () => {
    if (newTaskName.trim() === '') return;

    try {
      await axios.post('/api/v1/tasks', {
        name: newTaskName,
        status: 'backlog',
        priority: 'normal',
        area: 'other'
      }, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setNewTaskName('');
      setIsAdding(false);
      onAdd();
    } catch (error) {
      console.error('Error adding new task:', error);
    }
  };

  if (!isAdding) {
    return (
      <button
        className="w-full mt-0 px-4 py-2 bg-gray-200 outline-dashed outline-gray-400 hover:outline-gray-200 text-gray-400 rounded hover:bg-white hover:text-black transition-colors"
        onClick={() => setIsAdding(true)}
      >
        + New Task
      </button>
    );
  }

  return (
    <div className="bg-white p-4 mt-4 rounded shadow">
      <input
        type="text"
        value={newTaskName}
        onChange={(e) => setNewTaskName(e.target.value)}
        placeholder="Enter task name"
        className="w-full mb-2 p-2 border rounded"
        autoFocus
      />
      <div className="flex justify-end space-x-2">
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition-colors"
          onClick={() => setIsAdding(false)}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          onClick={handleAddTask}
        >
          Add Task
        </button>
      </div>
    </div>
  );
};

export default NewTaskCard;