// src/components/KanbanBoard.js
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import TaskCard from './TaskCard';
import NewTaskCard from './NewTaskCard';
import axios from '../axios';

const KanbanBoard = () => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);

  const columns = [
    { id: 'backlog', title: t('Backlog') },
    { id: 'current', title: t('Current') },
    { id: 'approval', title: t('Approval') },
    { id: 'done', title: t('Done') },
  ];

  useEffect(() => {
    fetchTasks();
    fetchUsers();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/api/v1/tasks', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setTasks(response.data);
    } catch (error) {
      console.error(t('Error fetching tasks:'), error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setUsers(response.data.entity.users);
    } catch (error) {
      console.error(t('Error fetching users:'), error);
    }
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
  
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }
  
    const task = tasks.find(t => t.id.toString() === draggableId);
    const oldStatus = task.status;
    const newStatus = destination.droppableId;
  
    // Check if the current user is allowed to move the task
    const currentUser = JSON.parse(sessionStorage.getItem('user'));
    if (
      (newStatus === 'done' && task.approved_by_id !== currentUser.id) ||
      (newStatus !== 'done' && task.assigned_to_id !== currentUser.id)
    ) {
      alert(t("You don't have permission to move this task."));
      return;
    }
  
    // Optimistic update
    const newTasks = tasks.map(t => {
      if (t.id === task.id) {
        return { ...t, status: newStatus };
      }
      return t;
    });
    setTasks(newTasks);
  
  // API call
  try {
    console.log('Updating task:', {
      taskId: task.id,
      oldStatus: oldStatus,
      newStatus: newStatus
    });

    await axios.put(`/api/v1/tasks/${task.id}`, 
      { 
        task: {
          status: newStatus
        }
      }, 
      { 
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      }
    );

    console.log('Task update successful');
  } catch (error) {
    console.error(t('Error updating task:'), error);
    setTasks(tasks);  // Revert to original state if the API call fails
    alert(t('Failed to update task. Please try again.'));
  }
};

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex space-x-4 p-4">
        {columns.map(column => (
          <div key={column.id} className="flex-1">
            <h2 className="text-lg font-semibold mb-2">{column.title}</h2>
            <Droppable droppableId={column.id}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="bg-gray-200 p-4 rounded-lg min-h-[200px]"
                >
                  {tasks
                    .filter(task => task.status === column.id)
                    .map((task, index) => (
                      <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TaskCard task={task} users={users} onUpdate={fetchTasks} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                  {column.id === 'backlog' && <NewTaskCard onAdd={fetchTasks} />}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </div>
    </DragDropContext>
  );
};

export default KanbanBoard;