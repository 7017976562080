import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../context/AuthContext';
import {
  MdOutlineSecurity,
  MdOutlineLockPerson,
  MdOutlineRuleFolder,
} from 'react-icons/md';
import { LuHeartHandshake } from 'react-icons/lu';
import { BsBarChartSteps } from 'react-icons/bs';
import { FaRegLightbulb } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';

const Wizards = () => {
  const { t } = useTranslation();

const actions = [
  {
    title: t('Know Yourself'),
    href: '/know-yourself',
    description: t('Know Yourself Description'),
    icon: FaRegLightbulb,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    enabled: true,
  },
  {
    title: t('Information Security'),
    href: '#',
    description: t('Information Security Description'),
    icon: MdOutlineSecurity,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    enabled: false,
  },
  {
    title: t('Data Privacy'),
    href: '#',
    description: t('Data Privacy Description'),
    icon: MdOutlineLockPerson,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    enabled: false,
  },
  {
    title: t('Legal, Tax & Financial Due Diligence'),
    href: '#',
    description: t('Legal, Tax & Financial Due Diligence Description'),
    icon: MdOutlineRuleFolder,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    enabled: false,
  },
  {
    title: t('Vision & Culture'),
    href: '#',
    description: t('Vision & Culture Description'),
    icon: LuHeartHandshake,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
    enabled: false,
  },
  {
    title: t('Quality Management System'),
    href: '#',
    description: t('Quality Management System Description'),
    icon: BsBarChartSteps,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    enabled: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StartupWizardBlock = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.lordicon.com/lusqsztk.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-white overflow-hidden rounded-lg shadow mb-8 mt-8">
      <div className="p-6 relative">
        <div>
          <span className="inline-flex rounded-lg p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
            <lord-icon
              src="https://cdn.lordicon.com/fttvwdlw.json"
              stroke="bold"
              trigger="loop"
              colors="primary:#2a9d8f,secondary:#2a9d8f"
              style={{ width: '40px', height: '40px' }}
            ></lord-icon>
          </span>
        </div>
        <div className="mt-8">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            <Link to="/startup-wizard" className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              {t('Startup Support')}
            </Link>
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            {t('Startup Support Description')}
            <br />
            <br />
            ⚠️ {' '}
            {t('Only available in Germany')}
          </p>
        </div>
        <span
          className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
          aria-hidden="true"
        >
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
          </svg>
        </span>
      </div>
    </div>
  );
};

  return (
    <div>
      <StartupWizardBlock />
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
              !action.enabled ? 'opacity-50 cursor-not-allowed' : ''
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'inline-flex rounded-lg p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                <Link to={action.enabled ? action.href : '#'} className={action.enabled ? 'focus:outline-none' : 'pointer-events-none'}>
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </Link>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {action.description}
              </p>
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wizards;