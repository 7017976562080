import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { RadioGroup } from '@headlessui/react';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import ShareholdersListGenerator from '../../../components/generated_documents/startup_wizard/ShareholdersListGenerator';
import StartupWizardDrawer from '../../../components/wizard_components/StartupWizardDrawer';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Formik, Form, Field } from 'formik';
import ArticlesOfAssociationGenerator from '../../../components/generated_documents/startup_wizard/ArticlesOfAssociationGenerator';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Debounce function to prevent excessive autosaves
function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

const StartupWizardArticlesOfAssociation = () => {
  const { wizardName, updateWizardData, liveMode, toggleLiveMode } = useWizard();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'articlesOfAssociation');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });

  const handleSave = async (values, { setSubmitting }) => {
    const result = await saveData(wizardName, 'articlesOfAssociation', values);
    if (result.success) {
      setNotification({ show: true, type: 'success', message: t('Data saved successfully') });
      updateWizardData('articlesOfAssociation', values, false);
      updateWizardData('articlesOfAssociation', values, true);
      setTimeout(() => navigate('/startup-wizard/shareholdersAgreement'), 1000);
    } else {
      setNotification({ show: true, type: 'error', message: t('Error saving data') });
    }
    setSubmitting(false);
  };

  const handleSaveAsDraft = async (values, { setSubmitting }) => {
    const result = await saveAsDraft(wizardName, 'articlesOfAssociation', values);
    if (result.success) {
      setNotification({ show: true, type: 'success', message: t('Saved as draft successfully') });
      updateWizardData('articlesOfAssociation', values, true);
    } else {
      setNotification({ show: true, type: 'error', message: t('Error saving as draft') });
    }
    setSubmitting(false);
  };

  const validationSchema = Yup.object({
    companyPurpose: Yup.string().required(t('Company purpose is required')),
  });

  const handleOpenDrawer = (title, content) => {
    setDrawerContent({ title, content });
    setDrawerOpen(true);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data) return <div>No data available</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <DataStatusBanner status={dataStatus} />

      <div className="text-left mt-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          {t('StartupWizard.articlesOfAssociation')}
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          {t('StartupWizard.articlesOfAssociationDescription')}
        </p>
      </div>

      <Formik
        initialValues={{ 
          ...{
          companyPurpose: '',
          operationRestriction: 'No',
          terminationDate: '',
          financialYearDeviation: 'No',
          financialYearStandard: 'Yes',
          financialYearStart: '',
          shareholderMeetingNotice: '4',
          shareholderMeetingQuorum: '50',
          meetingLocations: [
            'at the statuory seat of the company', 
            'via telephone or video conference', 
            'at any location with the consent of all shareholders'
          ],
          chairmanAppointmentQuorum: '50',
          ordinaryResolutionThreshold: '50',
          extraordinaryResolutionThreshold: '75',
          extraordinaryResolutions: [
            'Application for the opening of insolvency proceedings over the assets of the company',
            'Any amendment of the company\'s articles of association',
            'Any capital measures',
            'Approval of the annual financial accounts',
            'All measures concerning the management board',
            'Granting discharge to the managing directors',
            'Liquidating, dissolving or winding up the affairs of the company',
            'Effecting any type of merger or acquisition',
            'Conclusion, amendment and termination of enterprise agreements',
            'Sale, lease, exclusive licensing, transfer or other disposal of all or significant parts of the company\'s assets',
            'Request for admission of shares in the company to stock exchange trading',
            'Acquisition of own shares by the Company',
            'Split, merger and redemption of shares',
            'Any grant of rights or issuance of instruments with a right to convert into or exchange for shares in the company',
            'Adoption, amendment and cancellation of rules of procedure for the management board of the company',
            'Any of the measures above if undertaken on the level of a subsidiary',
            'Implementation of an employee incentive plan of the company'
          ],
          shareholderTermination: 'Yes'
        }, ...data,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSave}
        enableReinitialize={true}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, submitForm, setSubmitting }) => (
          <Form className="mt-12 space-y-8">
            {/* Include the AutoSave component here */}
            <AutoSave
              values={values}
              liveMode={liveMode}
              wizardName={wizardName}
              updateWizardData={updateWizardData}
            />

            <div>
              <div className="flex items-center">
                <label htmlFor="companyPurpose" className="block text-lg font-medium text-gray-700">
                  {t('What is the purpose of your company?')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('Company Purpose'),
                    "When choosing the 'Unternehmensgegenstand' for a German GmbH, ensure the purpose is clear, specific, and lawful while allowing room for growth and related activities. It should comply with legal requirements, reflect actual business operations, and be broad enough to avoid frequent amendments."
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <textarea
                name="companyPurpose"
                id="companyPurpose"
                value={values.companyPurpose}
                onChange={(e) => setFieldValue('companyPurpose', e.target.value)}
                rows="3"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
              ></textarea>
            </div>

            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('Do you plan to restrict the operations of the company to a certain period?')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('Operation Restriction'),
                    "Usually companies are established for an infinite period. But there are always exceptions. If there are no important reasons for a restricted period, don't restrict it."
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <RadioGroup
                value={values.operationRestriction}
                onChange={(value) => setFieldValue('operationRestriction', value)}
              >
                <div className="space-y-2">
                  {['Yes', 'No'].map((option) => (
                    <RadioGroup.Option key={option} value={option} className={({ checked }) =>
                      classNames(
                        'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                        checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                      )
                    }>
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label as="span" className={classNames(checked ? 'text-[#2A9D8F]' : 'text-gray-900', 'block text-sm font-medium')}>
                              {option}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {values.operationRestriction === 'Yes' && (
              <div>
                <label htmlFor="terminationDate" className="block text-lg font-medium text-gray-700">
                  {t('What is the termination date?')}
                </label>
                <input
                  type="date"
                  name="terminationDate"
                  id="terminationDate"
                  value={values.terminationDate}
                  onChange={(e) => setFieldValue('terminationDate', e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                />
              </div>
            )}

            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('Does your financial year deviate from the calendar year (01.01. - 31.12.)?')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('Financial Year Deviation'),
                    "You should only consider a deviating financial year for your German GmbH if it particularly aligns with seasonal business cycles, facilitates tax planning, fits with the financial years of a parent company, or helps streamline financial reporting."
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>

              <RadioGroup
                value={values.financialYearDeviation}
                onChange={(value) => setFieldValue('financialYearDeviation', value)}
              >
                <div className="space-y-2">
                  {['Yes', 'No'].map((option) => (
                    <RadioGroup.Option key={option} value={option} className={({ checked }) =>
                      classNames(
                        'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                        checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                      )
                    }>
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label as="span" className={classNames(checked ? 'text-[#2A9D8F]' : 'text-gray-900', 'block text-sm font-medium')}>
                              {option}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {values.financialYearDeviation === 'Yes' && (
              <>
                <div>
                  <label className="block text-lg font-medium text-gray-700 mb-2">
                    {t('Does your financial year go from 01.07. to 30.06.?')}
                  </label>
                  <RadioGroup
                    value={values.financialYearStandard}
                    onChange={(value) => setFieldValue('financialYearStandard', value)}
                  >
                    <div className="space-y-2">
                      {['Yes', 'No'].map((option) => (
                        <RadioGroup.Option key={option} value={option} className={({ checked }) =>
                          classNames(
                            'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                            checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                          )
                        }>
                          {({ active, checked }) => (
                            <>
                              <span className={classNames(
                                checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                                active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                              )}>
                                <span className="rounded-full bg-white w-1.5 h-1.5" />
                              </span>
                              <div className="ml-3 flex flex-col">
                                <RadioGroup.Label as="span" className={classNames(checked ? 'text-[#2A9D8F]' : 'text-gray-900', 'block text-sm font-medium')}>
                                  {option}
                                </RadioGroup.Label>
                              </div>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                {values.financialYearStandard === 'No' && (
                  <div>
                    <label htmlFor="financialYearStart" className="block text-lg font-medium text-gray-700">
                      {t('When does the financial year start?')}
                    </label>
                    <input
                      type="date"
                      name="financialYearStart"
                      id="financialYearStart"
                      value={values.financialYearStart}
                      onChange={(e) => setFieldValue('financialYearStart', e.target.value)}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
                    />
                  </div>
                )}
              </>
            )}

            <div>
              <div className="flex items-center">
                <label htmlFor="shareholderMeetingNotice" className="block text-lg font-medium text-gray-700">
                  {t('How many weeks in advance must shareholders receive the invitation to a shareholder meeting?')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('Shareholder Meeting Notice'),
                    "Shareholder meetings are the main forum for decision-making, where important matters such as appointing managing directors, approving financial statements, and amending the articles of association are discussed and voted on. Because of the importance of the shareholder meeting, enough time should be given to shareholders to make plans to attend or send a representative. Resolutions passed during these meetings or in writing are binding on the company. Depending on the nature of the decision, resolutions may require a simple or qualified majority, and some resolutions must be notarized and registered with the commercial register. Proper documentation and adherence to voting rights and procedures are crucial for the validity of shareholder resolutions."
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <select
                name="shareholderMeetingNotice"
                id="shareholderMeetingNotice"
                value={values.shareholderMeetingNotice}
                onChange={(e) => setFieldValue('shareholderMeetingNotice', e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm rounded-md"
              >
                {[...Array(12)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>{`${i + 1} week${i + 1 > 1 ? 's' : ''}`}</option>
                ))}
              </select>
            </div>

            <div>
              <div className="flex items-center">
                <label htmlFor="shareholderMeetingQuorum" className="block text-lg font-medium text-gray-700">
                  {t('What quorum needs to be present in a shareholder meeting?')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('Shareholder Meeting Quorum'),
                    "The quorum in a German GmbH shareholder meeting ensures that a minimum number of shareholders or percentage of share capital is present before any resolutions can be validly passed. While the German GmbH Act does not specify a quorum requirement, it is typically governed by the company's articles of association. If a quorum is not met, the meeting may be postponed, and in some cases, a second meeting can be convened with lower or no quorum requirements. The quorum ensures fair representation in the company's decision-making process."
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <input
                type="range"
                name="shareholderMeetingQuorum"
                id="shareholderMeetingQuorum"
                min="50"
                max="100"
                step="5"
                value={values.shareholderMeetingQuorum}
                onChange={(e) => setFieldValue('shareholderMeetingQuorum', e.target.value)}
                className="mt-1 block w-full custom-range"
              />
              <div className="mt-2 text-sm text-gray-600">
                  {values.shareholderMeetingQuorum}%
                  {values.shareholderMeetingQuorum >= 80 && (
                    <span className="text-red-300"> ({t('Not Recommended')})</span>
                  )}
                </div>
            </div>

            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('Where shall shareholder meetings be allowed to take place?')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('Shareholder Meeting Locations'),
                    "Participation must be feasible for all shareholders."
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="space-y-2">
                {['at the statuory seat of the company', 'via telephone or video conference', 'at any location with the consent of all shareholders'].map((option) => (
                  <label key={option} className="flex items-center">
                    <input
                      type="checkbox"
                      name="meetingLocations"
                      value={option}
                      checked={values.meetingLocations.includes(option)}
                      onChange={(e) => {
                        const newMeetingLocations = e.target.checked
                          ? [...values.meetingLocations, option]
                          : values.meetingLocations.filter(loc => loc !== option);
                        setFieldValue('meetingLocations', newMeetingLocations);
                      }}
                      className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700">{option}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <label htmlFor="chairmanAppointmentQuorum" className="block text-lg font-medium text-gray-700">
                  {t('Which quorum shall appoint the chairman of the meeting?')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('Chairman Appointment Quorum'),
                    "The chairman of a shareholder meeting in a German GmbH is responsible for ensuring that the meeting is conducted according to legal requirements and company procedures. They oversee the quorum, manage the agenda, facilitate discussions, supervise voting, and ensure that all decisions are properly recorded and communicated. The chairman plays a crucial role in maintaining order, ensuring fairness, and safeguarding the legal validity of the shareholder resolutions passed during the meeting."
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <input
                type="range"
                name="chairmanAppointmentQuorum"
                id="chairmanAppointmentQuorum"
                min="50"
                max="100"
                step="5"
                value={values.chairmanAppointmentQuorum}
                onChange={(e) => setFieldValue('chairmanAppointmentQuorum', e.target.value)}
                className="mt-1 block w-full custom-range"
              />
              <div className="mt-2 text-sm text-gray-600">
                  {values.chairmanAppointmentQuorum}%
                  {values.chairmanAppointmentQuorum >= 80 && (
                    <span className="text-red-300"> ({t('Not Recommended')})</span>
                  )}
                </div>
            </div>

            <div>
              <label htmlFor="ordinaryResolutionThreshold" className="block text-lg font-medium text-gray-700">
                {t('What is the general threshold for ordinary resolutions?')}
              </label>
              <input
                type="range"
                name="ordinaryResolutionThreshold"
                id="ordinaryResolutionThreshold"
                min="50"
                max="100"
                step="5"
                value={values.ordinaryResolutionThreshold}
                onChange={(e) => setFieldValue('ordinaryResolutionThreshold', e.target.value)}
                className="mt-1 block w-full custom-range"
              />
              <div className="mt-2 text-sm text-gray-600">
                  {values.ordinaryResolutionThreshold}%
                  {values.ordinaryResolutionThreshold >= 80 && (
                    <span className="text-red-300"> ({t('Not Recommended')})</span>
                  )}
                </div>
            </div>

            <div>
              <label htmlFor="extraordinaryResolutionThreshold" className="block text-lg font-medium text-gray-700">
                {t('What is the threshold for extraordinary resolutions?')}
              </label>
              <input
                type="range"
                name="extraordinaryResolutionThreshold"
                id="extraordinaryResolutionThreshold"
                min="75"
                max="100"
                step="1"
                value={values.extraordinaryResolutionThreshold}
                onChange={(e) => setFieldValue('extraordinaryResolutionThreshold', e.target.value)}
                className="mt-1 block w-full custom-range"
              />
              <div className="mt-2 text-sm text-gray-600">
                  {values.extraordinaryResolutionThreshold}%
                  {values.extraordinaryResolutionThreshold >= 88 && (
                    <span className="text-red-300"> ({t('Not Recommended')})</span>
                  )}
                </div>
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                {t('Choose from the following catalog of measures what shall be summarised under extraordinary resolutions?')}
              </label>
              <div className="space-y-2">
                {[
                  'Application for the opening of insolvency proceedings over the assets of the company',
                  'Any amendment of the company\'s articles of association',
                  'Any capital measures',
                  'Approval of the annual financial accounts',
                  'All measures concerning the management board',
                  'Granting discharge to the managing directors',
                  'Liquidating, dissolving or winding up the affairs of the company',
                  'Effecting any type of merger or acquisition',
                  'Conclusion, amendment and termination of enterprise agreements',
                  'Sale, lease, exclusive licensing, transfer or other disposal of all or significant parts of the company\'s assets',
                  'Request for admission of shares in the company to stock exchange trading',
                  'Acquisition of own shares by the Company',
                  'Split, merger and redemption of shares',
                  'Any grant of rights or issuance of instruments with a right to convert into or exchange for shares in the company',
                  'Adoption, amendment and cancellation of rules of procedure for the management board of the company',
                  'Any of the measures above if undertaken on the level of a subsidiary',
                  'Implementation of an employee incentive plan of the company'
                ].map((option) => (
                  <label key={option} className="flex items-center">
                    <input
                      type="checkbox"
                      name="extraordinaryResolutions"
                      value={option}
                      checked={values.extraordinaryResolutions.includes(option)}
                      onChange={(e) => {
                        const newExtraordinaryResolutions = e.target.checked
                          ? [...values.extraordinaryResolutions, option]
                          : values.extraordinaryResolutions.filter(loc => loc !== option);
                        setFieldValue('extraordinaryResolutions', newExtraordinaryResolutions);
                      }}
                      className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700">{option}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('Shall there be the option for shareholders to terminate their involvement with the company?')}
                </label>
                <button
                  type="button"
                  onClick={() => handleOpenDrawer(
                    t('Shareholder Termination'),
                    "Shareholders may wish to terminate their involvement with the GmbH. This happens from time to time especially among first-time founders. The termination mechanism can be a useful instrument to keep the capital table clean and use the outstanding shares as effectively as possible."
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <RadioGroup
                value={values.shareholderTermination}
                onChange={(value) => setFieldValue('shareholderTermination', value)}
              >
                <div className="space-y-2">
                  {['Yes', 'No'].map((option) => (
                    <RadioGroup.Option key={option} value={option} className={({ checked }) =>
                      classNames(
                        'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                        checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                      )
                    }>
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label as="span" className={classNames(checked ? 'text-[#2A9D8F]' : 'text-gray-900', 'block text-sm font-medium')}>
                              {option}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            <ActionButtons
              onCancel={() => navigate('/startup-wizard')}
              onDraft={() => handleSaveAsDraft(values, { setSubmitting })}
              onSubmit={submitForm}
              isSubmitting={isSubmitting}
              currentPage="articlesOfAssociation"
            />
          </Form>
        )}
      </Formik>

      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

      <div className="mt-8">
        <p className="mb-2">{t('Generate a draft of your "List of shareholders" based on the information provided in previous steps:')}</p>
        <ShareholdersListGenerator />
      </div>

      <div className="mt-8">
        <p className="mb-2">{t('Generate a draft of your "Articles of Association" based on the information provided:')}</p>
        <ArticlesOfAssociationGenerator />
      </div>

      <Notification
        show={notification.show}
        setShow={(show) => setNotification(prev => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />

      <StartupWizardDrawer
        isOpen={drawerOpen}
        title={drawerContent.title}
        content={drawerContent.content}
        onClose={() => setDrawerOpen(false)}
      />
    </div>
  );
};

export default StartupWizardArticlesOfAssociation;

// AutoSave Component
const AutoSave = ({ values, liveMode, wizardName, updateWizardData }) => {
  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {
    if (liveMode && values) {
      const result = await saveAsDraft(wizardName, 'articlesOfAssociation', values);
      if (result.success) {
        updateWizardData('articlesOfAssociation', values, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, values, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when liveMode and values change
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, values]);

  return null;
};