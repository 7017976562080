import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'lord-icon-element';
import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import RegistrationSuccess from './pages/auth/RegistrationSuccess';
import LoginSuccess from './pages/auth/LoginSuccess';
import CompanyDashboard from './pages/CompanyDashboard';
import PortfolioDashboard from './pages/PortfolioDashboard';
import Reports from './pages/Reports';
import Alerts from './pages/Alerts';
import Wizards from './pages/Wizards';
import KnowYourselfOverview from './pages/wizards/KnowYourselfWizard/KnowYourselfOverview';
import KnowYourselfWizardIndustry from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardIndustry';
import KnowYourselfWizardMarket from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardMarket';
import KnowYourselfWizardBusinessModel from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardBusinessModel';
import KnowYourselfWizardProduct from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardProduct';
import KnowYourselfWizardTeam from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardTeam';
import KnowYourselfWizardInvestment from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardInvestment';
import StartupWizardOverview from './pages/wizards/StartupWizard/StartupWizardOverview';
import StartupWizardIntroduction from './pages/wizards/StartupWizard/StartupWizardIntroduction';
import StartupWizardLegalForm from './pages/wizards/StartupWizard/StartupWizardLegalForm';
import StartupWizardCompany from './pages/wizards/StartupWizard/StartupWizardCompany';
import StartupWizardFounders from './pages/wizards/StartupWizard/StartupWizardFounders';
import StartupWizardArticlesOfAssociation from './pages/wizards/StartupWizard/StartupWizardArticlesOfAssociation';
import StartupWizardShareholdersAgreement from './pages/wizards/StartupWizard/StartupWizardShareholdersAgreement';
import StartupWizardBankAccount from './pages/wizards/StartupWizard/StartupWizardBankAccount';
import StartupWizardEquityStory from './pages/wizards/StartupWizard/StartupWizardEquityStory';
import StartupWizardNotary from './pages/wizards/StartupWizard/StartupWizardNotary';
import ISMSOverview from './pages/wizards/ISMS/ISMSOverview';
import ISMSImplementControls from './pages/wizards/ISMS/Implementation/ISMSImplementControls';
import ISMSSecurityObjectives from './pages/wizards/ISMS/InformationSecurityObjectives/ISMSSecurityObjectives';
import ISMSCoreAspects from './pages/wizards/ISMS/Introduction/ISMSCoreAspects';
import ISMSReferencesAndMaterials from './pages/wizards/ISMS/Introduction/ISMSReferencesAndMaterials';
import ISMSWelcome from './pages/wizards/ISMS/Introduction/ISMSWelcome';
import ISMSWhatDoINeed from './pages/wizards/ISMS/Introduction/ISMSWhatDoINeed';
import ISMSCommitmentCharta from './pages/wizards/ISMS/LeadershipCommitment/ISMSCommitmentCharta';
import ISMSLeadership from './pages/wizards/ISMS/LeadershipCommitment/ISMSLeadership';
import ISMSNextSteps from './pages/wizards/ISMS/LeadershipCommitment/ISMSNextSteps';
import ISMSSecurityPolicy from './pages/wizards/ISMS/LeadershipCommitment/ISMSSecurityPolicy';
import ISMSTopManagementResponsibilities from './pages/wizards/ISMS/LeadershipCommitment/ISMSTopManagementResponsibilities';
import ISMSOperativeDocumentation from './pages/wizards/ISMS/Operation/ISMSOperativeDocumentation';
import ISMSExternalContext from './pages/wizards/ISMS/OrganizationalContextAndScope/ISMSExternalContext';
import ISMSGettingReady from './pages/wizards/ISMS/OrganizationalContextAndScope/ISMSGettingReady';
import ISMSInternalContext from './pages/wizards/ISMS/OrganizationalContextAndScope/ISMSInternalContext';
import ISMSOrganizationSecurityPerspective from './pages/wizards/ISMS/OrganizationalContextAndScope/ISMSOrganizationSecurityPerspective';
import ISMSPrioritizeRequirements from './pages/wizards/ISMS/OrganizationalContextAndScope/ISMSPrioritizeRequirements';
import ISMSStakeholderNeeds from './pages/wizards/ISMS/OrganizationalContextAndScope/ISMSStakeholderNeeds';
import ISMSStakeholderRequirements from './pages/wizards/ISMS/OrganizationalContextAndScope/ISMSStakeholderRequirements';
import ISMSUnderstandingOrganizationContext from './pages/wizards/ISMS/OrganizationalContextAndScope/ISMSUnderstandingOrganizationContext';
import ISMSPerformanceEvaluation from './pages/wizards/ISMS/PerformanceEvaluation/ISMSPerformanceEvaluation';
import ISMSAnalyzingRisks from './pages/wizards/ISMS/RiskAnalysis/ISMSAnalyzingRisks';
import ISMSImpactAssessment from './pages/wizards/ISMS/RiskAnalysis/ISMSImpactAssessment';
import ISMSIncidentLikelihood from './pages/wizards/ISMS/RiskAnalysis/ISMSIncidentLikelihood';
import ISMSRiskLevelDetermination from './pages/wizards/ISMS/RiskAnalysis/ISMSRiskLevelDetermination';
import ISMSDreamTeam from './pages/wizards/ISMS/RiskAssessment/ISMSDreamTeam';
import ISMSRiskAssessment from './pages/wizards/ISMS/RiskAssessment/ISMSRiskAssessment';
import ISMSPrioritizedRiskList from './pages/wizards/ISMS/RiskEvaluation/ISMSPrioritizedRiskList';
import ISMSRiskEvaluation from './pages/wizards/ISMS/RiskEvaluation/ISMSRiskEvaluation';
import ISMSRiskPrioritization from './pages/wizards/ISMS/RiskEvaluation/ISMSRiskPrioritization';
import ISMSAssetList from './pages/wizards/ISMS/RiskIdentification/ISMSAssetList';
import ISMSPrimaryAssetInformation from './pages/wizards/ISMS/RiskIdentification/ISMSPrimaryAssetInformation';
import ISMSPrimaryAssetProcesses from './pages/wizards/ISMS/RiskIdentification/ISMSPrimaryAssetProcesses';
import ISMSPrimaryAssetSubprocesses from './pages/wizards/ISMS/RiskIdentification/ISMSPrimaryAssetSubprocesses';
import ISMSProtectionTargets from './pages/wizards/ISMS/RiskIdentification/ISMSProtectionTargets';
import ISMSRiskEvents from './pages/wizards/ISMS/RiskIdentification/ISMSRiskEvents';
import ISMSRiskScenarios from './pages/wizards/ISMS/RiskIdentification/ISMSRiskScenarios';
import ISMSSupportingAssets from './pages/wizards/ISMS/RiskIdentification/ISMSSupportingAssets';
import ISMSThreats from './pages/wizards/ISMS/RiskIdentification/ISMSThreats';
import ISMSVulnerabilities from './pages/wizards/ISMS/RiskIdentification/ISMSVulnerabilities';
import ISMSProtectionClassification from './pages/wizards/ISMS/RiskPolicy/ISMSProtectionClassification';
import ISMSRiskAcceptance from './pages/wizards/ISMS/RiskPolicy/ISMSRiskAcceptance';
import ISMSRiskActions from './pages/wizards/ISMS/RiskPolicy/ISMSRiskActions';
import ISMSRiskEvaluationCriteria from './pages/wizards/ISMS/RiskPolicy/ISMSRiskEvaluationCriteria';
import ISMSRiskImpactCriteria from './pages/wizards/ISMS/RiskPolicy/ISMSRiskImpactCriteria';
import ISMSRiskPolicy from './pages/wizards/ISMS/RiskPolicy/ISMSRiskPolicy';
import ISMSRiskPolicyReview from './pages/wizards/ISMS/RiskPolicy/ISMSRiskPolicyReview';
import ISMSRisks from './pages/wizards/ISMS/RiskPolicy/ISMSRisks';
import ISMSRiskAvoidance from './pages/wizards/ISMS/RiskTreatment/ISMSRiskAvoidance';
import ISMSRiskControls from './pages/wizards/ISMS/RiskTreatment/ISMSRiskControls';
import ISMSRiskRetention from './pages/wizards/ISMS/RiskTreatment/ISMSRiskRetention';
import ISMSRiskSharing from './pages/wizards/ISMS/RiskTreatment/ISMSRiskSharing';
import ISMSRiskTreatment from './pages/wizards/ISMS/RiskTreatment/ISMSRiskTreatment';
import ISMSRiskTreatmentEffects from './pages/wizards/ISMS/RiskTreatment/ISMSRiskTreatmentEffects';
import ISMSStatementOfApplicability from './pages/wizards/ISMS/RiskTreatment/ISMSStatementOfApplicability'; 
import ISMSRiskControlList from './pages/wizards/ISMS/ISMSRiskControlList';
import { WizardProvider } from './components/wizard_components/WizardContext';
import './components/wizard_components/Radiothumbs.css';
import Portfolio from './pages/Portfolio';
import Team from './pages/Team';
import Settings from './pages/Settings';
import Tasks from './pages/Tasks';
import Logout from './pages/Logout';
import LogoutSuccess from './pages/auth/LogoutSuccess';
import AuthProvider, { AuthContext } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import DashboardLayout from './components/DashboardLayout';
import ForgotPassword from './pages/auth/ForgotPassword';
import PasswordResetSuccess from './pages/auth/PasswordResetSuccess';
import ResetPassword from './pages/auth/ResetPassword';
import PasswordResetConfirmation from './pages/auth/PasswordResetConfirmation';
import DeletionSuccess from './pages/auth/DeletionSuccess';
import CompleteAccountSetup from './pages/auth/CompleteAccountSetup';
import SessionExpired from './pages/auth/SessionExpired';
import Home from './components/Home'; // Import the Home component
import './index.css'; // Import global styles including tailwind and other custom styles
import Footer from './components/Footer'; // Import Footer component

function App() {
  const { i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const checkI18nInitialized = () => {
      if (i18n.isInitialized && i18n.hasLoadedNamespace('translation')) {
        setIsInitialized(true);
      }
    };

    i18n.on('initialized', checkI18nInitialized);
    i18n.on('loaded', checkI18nInitialized);

    // Clean up listeners on unmount
    return () => {
      i18n.off('initialized', checkI18nInitialized);
      i18n.off('loaded', checkI18nInitialized);
    };
  }, [i18n]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  const token = sessionStorage.getItem('authToken');
  const expiration = sessionStorage.getItem('tokenExpiresAt');
  const now = Math.floor(new Date().getTime() / 1000);

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={token && now < expiration ? <Navigate to="/dashboard" /> : <Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/registration-success" element={<RegistrationSuccess />} />
          <Route path="/login-successful" element={<LoginSuccess />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
          <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
          <Route path="/password-reset-confirmation" element={<PasswordResetConfirmation />} />
          <Route path="/deletion-success" element={<DeletionSuccess />} />
          <Route path="/complete-account-setup/:token" element={<CompleteAccountSetup />} />
          <Route path="/session-expired" element={<SessionExpired />} />
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<PrivateRoute><DashboardWrapper /></PrivateRoute>} />
            <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
            <Route path="/alerts" element={<PrivateRoute><Alerts /></PrivateRoute>} />
            <Route path="/wizards" element={<PrivateRoute><Wizards /></PrivateRoute>} />
            <Route path="/know-yourself" element={<PrivateRoute><KnowYourselfOverview /></PrivateRoute>} />
            <Route path="/know-yourself/industry" element={<PrivateRoute><KnowYourselfWizardIndustry /></PrivateRoute>} />
            <Route path="/know-yourself/market" element={<PrivateRoute><KnowYourselfWizardMarket /></PrivateRoute>} />
            <Route path="/know-yourself/business-model" element={<PrivateRoute><KnowYourselfWizardBusinessModel /></PrivateRoute>} />
            <Route path="/know-yourself/product" element={<PrivateRoute><KnowYourselfWizardProduct /></PrivateRoute>} />
            <Route path="/know-yourself/team" element={<PrivateRoute><KnowYourselfWizardTeam /></PrivateRoute>} />
            <Route path="/know-yourself/investment" element={<PrivateRoute><KnowYourselfWizardInvestment /></PrivateRoute>} />

            {/* Startup Wizard routes */}
            <Route path="/startup-wizard/*" element={
              <WizardProvider wizardName="startup">
                <Routes>
                  <Route index element={<PrivateRoute><StartupWizardOverview /></PrivateRoute>} />
                  <Route path="introduction" element={<PrivateRoute><StartupWizardIntroduction /></PrivateRoute>} />
                  <Route path="legalForm" element={<PrivateRoute><StartupWizardLegalForm /></PrivateRoute>} />
                  <Route path="company" element={<PrivateRoute><StartupWizardCompany /></PrivateRoute>} />
                  <Route path="founders" element={<PrivateRoute><StartupWizardFounders /></PrivateRoute>} />
                  <Route path="articlesOfAssociation" element={<PrivateRoute><StartupWizardArticlesOfAssociation /></PrivateRoute>} />
                  <Route path="shareholdersAgreement" element={<PrivateRoute><StartupWizardShareholdersAgreement /></PrivateRoute>} />
                  <Route path="bankAccount" element={<PrivateRoute><StartupWizardBankAccount /></PrivateRoute>} />
                  <Route path="equityStory" element={<PrivateRoute><StartupWizardEquityStory /></PrivateRoute>} />
                  <Route path="notary" element={<PrivateRoute><StartupWizardNotary /></PrivateRoute>} />
                </Routes>
              </WizardProvider>
            } />

            {/* ISMS routes */}
            <Route path="/isms/*" element={
              <WizardProvider wizardName="isms">
                <Routes>
                  <Route path="ismsoverview" element={<PrivateRoute><ISMSOverview /></PrivateRoute>} />
                  <Route path="implementation/controls" element={<PrivateRoute><ISMSImplementControls /></PrivateRoute>} />
                  <Route path="security-objectives" element={<PrivateRoute><ISMSSecurityObjectives /></PrivateRoute>} />
                  <Route path="introduction/core-aspects" element={<PrivateRoute><ISMSCoreAspects /></PrivateRoute>} />
                  <Route path="introduction/references-and-materials" element={<PrivateRoute><ISMSReferencesAndMaterials /></PrivateRoute>} />
                  <Route path="introduction/welcome" element={<PrivateRoute><ISMSWelcome /></PrivateRoute>} />
                  <Route path="introduction/what-do-i-need" element={<PrivateRoute><ISMSWhatDoINeed /></PrivateRoute>} />
                  <Route path="leadership-commitment/commitment-charta" element={<PrivateRoute><ISMSCommitmentCharta /></PrivateRoute>} />
                  <Route path="leadership-commitment/leadership" element={<PrivateRoute><ISMSLeadership /></PrivateRoute>} />
                  <Route path="leadership-commitment/next-steps" element={<PrivateRoute><ISMSNextSteps /></PrivateRoute>} />
                  <Route path="leadership-commitment/security-policy" element={<PrivateRoute><ISMSSecurityPolicy /></PrivateRoute>} />
                  <Route path="leadership-commitment/top-management-responsibilities" element={<PrivateRoute><ISMSTopManagementResponsibilities /></PrivateRoute>} />
                  <Route path="operation/operative-documentation" element={<PrivateRoute><ISMSOperativeDocumentation /></PrivateRoute>} />
                  <Route path="organizational-context-and-scope/external-context" element={<PrivateRoute><ISMSExternalContext /></PrivateRoute>} />
                  <Route path="organizational-context-and-scope/getting-ready" element={<PrivateRoute><ISMSGettingReady /></PrivateRoute>} />
                  <Route path="organizational-context-and-scope/internal-context" element={<PrivateRoute><ISMSInternalContext /></PrivateRoute>} />
                  <Route path="organizational-context-and-scope/organization-security-perspective" element={<PrivateRoute><ISMSOrganizationSecurityPerspective /></PrivateRoute>} />
                  <Route path="organizational-context-and-scope/prioritize-requirements" element={<PrivateRoute><ISMSPrioritizeRequirements /></PrivateRoute>} />
                  <Route path="organizational-context-and-scope/stakeholder-needs" element={<PrivateRoute><ISMSStakeholderNeeds /></PrivateRoute>} />
                  <Route path="organizational-context-and-scope/stakeholder-requirements" element={<PrivateRoute><ISMSStakeholderRequirements /></PrivateRoute>} />
                  <Route path="organizational-context-and-scope/understanding-organization-context" element={<PrivateRoute><ISMSUnderstandingOrganizationContext /></PrivateRoute>} />
                  <Route path="performance-evaluation" element={<PrivateRoute><ISMSPerformanceEvaluation /></PrivateRoute>} />
                  <Route path="risk-analysis/analyzing-risks" element={<PrivateRoute><ISMSAnalyzingRisks /></PrivateRoute>} />
                  <Route path="risk-analysis/impact-assessment" element={<PrivateRoute><ISMSImpactAssessment /></PrivateRoute>} />
                  <Route path="risk-analysis/incident-likelihood" element={<PrivateRoute><ISMSIncidentLikelihood /></PrivateRoute>} />
                  <Route path="risk-analysis/risk-level-determination" element={<PrivateRoute><ISMSRiskLevelDetermination /></PrivateRoute>} />
                  <Route path="risk-assessment/dream-team" element={<PrivateRoute><ISMSDreamTeam /></PrivateRoute>} />
                  <Route path="risk-assessment/risk-assessment" element={<PrivateRoute><ISMSRiskAssessment /></PrivateRoute>} />
                  <Route path="risk-evaluation/prioritized-risk-list" element={<PrivateRoute><ISMSPrioritizedRiskList /></PrivateRoute>} />
                  <Route path="risk-evaluation/risk-evaluation" element={<PrivateRoute><ISMSRiskEvaluation /></PrivateRoute>} />
                  <Route path="risk-evaluation/risk-prioritization" element={<PrivateRoute><ISMSRiskPrioritization /></PrivateRoute>} />
                  <Route path="risk-identification/asset-list" element={<PrivateRoute><ISMSAssetList /></PrivateRoute>} />
                  <Route path="risk-identification/primary-asset-information" element={<PrivateRoute><ISMSPrimaryAssetInformation /></PrivateRoute>} />
                  <Route path="risk-identification/primary-asset-processes" element={<PrivateRoute><ISMSPrimaryAssetProcesses /></PrivateRoute>} />
                  <Route path="risk-identification/primary-asset-subprocesses" element={<PrivateRoute><ISMSPrimaryAssetSubprocesses /></PrivateRoute>} />
                  <Route path="risk-identification/protection-targets" element={<PrivateRoute><ISMSProtectionTargets /></PrivateRoute>} />
                  <Route path="risk-identification/risk-events" element={<PrivateRoute><ISMSRiskEvents /></PrivateRoute>} />
                  <Route path="risk-identification/risk-scenarios" element={<PrivateRoute><ISMSRiskScenarios /></PrivateRoute>} />
                  <Route path="risk-identification/supporting-assets" element={<PrivateRoute><ISMSSupportingAssets /></PrivateRoute>} />
                  <Route path="risk-identification/threats" element={<PrivateRoute><ISMSThreats /></PrivateRoute>} />
                  <Route path="risk-identification/vulnerabilities" element={<PrivateRoute><ISMSVulnerabilities /></PrivateRoute>} />
                  <Route path="risk-policy/protection-classification" element={<PrivateRoute><ISMSProtectionClassification /></PrivateRoute>} />
                  <Route path="risk-policy/risk-acceptance" element={<PrivateRoute><ISMSRiskAcceptance /></PrivateRoute>} />
                  <Route path="risk-policy/risk-actions" element={<PrivateRoute><ISMSRiskActions /></PrivateRoute>} />
                  <Route path="risk-policy/risk-evaluation-criteria" element={<PrivateRoute><ISMSRiskEvaluationCriteria /></PrivateRoute>} />
                  <Route path="risk-policy/risk-impact-criteria" element={<PrivateRoute><ISMSRiskImpactCriteria /></PrivateRoute>} />
                  <Route path="risk-policy/risk-policy" element={<PrivateRoute><ISMSRiskPolicy /></PrivateRoute>} />
                  <Route path="risk-policy/risk-policy-review" element={<PrivateRoute><ISMSRiskPolicyReview /></PrivateRoute>} />
                  <Route path="risk-policy/risks" element={<PrivateRoute><ISMSRisks /></PrivateRoute>} />
                  <Route path="risk-treatment/risk-avoidance" element={<PrivateRoute><ISMSRiskAvoidance /></PrivateRoute>} />
                  <Route path="risk-treatment/risk-controls" element={<PrivateRoute><ISMSRiskControls /></PrivateRoute>} />
                  <Route path="risk-treatment/risk-retention" element={<PrivateRoute><ISMSRiskRetention /></PrivateRoute>} />
                  <Route path="risk-treatment/risk-sharing" element={<PrivateRoute><ISMSRiskSharing /></PrivateRoute>} />
                  <Route path="risk-treatment/risk-treatment" element={<PrivateRoute><ISMSRiskTreatment /></PrivateRoute>} />
                  <Route path="risk-treatment/risk-treatment-effects" element={<PrivateRoute><ISMSRiskTreatmentEffects /></PrivateRoute>} />
                  <Route path="risk-treatment/statement-of-applicability" element={<PrivateRoute><ISMSStatementOfApplicability /></PrivateRoute>} />
                  <Route path="risk-treatment/risk-control-list" element={<PrivateRoute><ISMSRiskControlList /></PrivateRoute>} />
                </Routes>
              </WizardProvider>
            } />

            <Route path="/portfolio" element={<PrivateRoute><Portfolio /></PrivateRoute>} />
            <Route path="/team" element={<AdminRoute><Team /></AdminRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/tasks" element={<PrivateRoute><Tasks /></PrivateRoute>} />
            <Route path="/logout" element={<Logout />} />
          </Route>
          <Route path="/logout-success" element={<LogoutSuccess />} />
        </Routes>
        <Routes>
          {/* Footer routes */}
          <Route path="/" element={<Footer />} />
          <Route path="/login" element={<Footer />} />
          <Route path="/signup" element={<Footer />} />
          <Route path="/registration-success" element={<Footer />} />
          <Route path="/login-successful" element={<Footer />} />
          <Route path="/forgot-password" element={<Footer />} />
          <Route path="/password-reset-success" element={<Footer />} />
          <Route path="/reset-password/:resetToken" element={<Footer />} />
          <Route path="/password-reset-confirmation" element={<Footer />} />
          <Route path="/deletion-success" element={<Footer />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

function DashboardWrapper() {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <div>Loading...</div>;
  }

  return user.entity_type === 'Company' ? <CompanyDashboard /> : <PortfolioDashboard />;
}

export default App;
